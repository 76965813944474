<script>
import { reviewLeadFail } from '@/services/clueIncubationServices.js'
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    checkedItems: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: '',
    },
  },

  data() {
    return {
      visible: false,
      form: {
        planTime: '',
        reviewStatus: '',
        reviewRemark: '',
      },
      minDate: null,
      maxDate: null,
      show: false,
    }
  },
  computed: {
    dictList() {
      return this.getDictNameByCode(1013, '', 'array').filter(
        (item) => item.code !== '1013001'
      )
    },
  },
  watch: {
    value: {
      handler(val) {
        this.form.reviewStatus = ''
        this.form.reviewRemark = ''
        this.form.planTime = ''
        this.visible = val
      },
      immediate: true,
    },
    visible: {
      handler(val) {
        this.$emit('change', val)
      },
    },
  },
  methods: {
    async save(done) {
      try {
        await reviewLeadFail(this.getData())
        done()
        this.$toast(this.$t('审核成功'))
        this.$emit('confirm')
      } catch (error) {
        done(false)
      }
    },
    async handlerConfirm(action, done) {
      if (action === 'confirm') {
        await this.$refs.formRef.validate().then(
          () => {
            this.save(done)
          },
          () => {
            done(false)
          }
        )
      } else {
        done()
      }
    },
    getData() {
      const obj = {
        ids: this.checkedItems,
        reviewStatus: this.form.reviewStatus,
        reviewRemark: this.form.reviewRemark,
        type: this.type,
      }
      if (obj.reviewStatus == '1013003' && this.form.planTime) {
        obj.planTime = this.$moment(this.form.planTime).format('YYYY-MM-DD HH:mm:ss')
      }
      return obj
    },
    dateConfirm(date) {
      this.show = false
      this.form.planTime = this.$moment(date).format('YYYY-MM-DD HH:mm')
    },
    onSelectTime() {
      this.minDate = new Date()
      this.maxDate = new Date(
        this.$moment()
          .add(15, 'd')
          .format('YYYY-MM-DD HH:mm:ss')
      )
      this.show = true
    },
  },
}
</script>
<template>
  <van-dialog
    v-model="visible"
    show-cancel-button
    :before-close="handlerConfirm"
    confirmButtonColor="#B9921A"
    :cancel-button-text="$t('取消')"
    :confirm-button-text="$t('确认')"
  >
    <div class="defeat-from">
      <van-form ref="formRef">
        <van-field
          v-model="form.reviewStatus"
          required
          :label="$t('审核结果')"
          :rules="[{ required: true, message: $t('请选择审核结果') }]"
          style="display: block;"
        >
          <template #input>
            <van-radio-group v-model="form.reviewStatus" direction="horizontal">
              <van-radio
                v-for="item in dictList"
                :key="item.code"
                :name="item.code"
              >
                {{ item.name }}
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          v-if="form.reviewStatus == '1013003'"
          v-model="form.planTime"
          required
          :label="$t('下次跟进时间')"
          :placeholder="$t('请选择下次跟进时间')"
          :rules="[{ required: true }]"
          readonly
          right-icon="arrow"
          @click="onSelectTime"
        />
        <van-field
          v-model="form.reviewRemark"
          :required="form.reviewStatus === '1013003'"
          show-word-limit
          :label="$t('审核备注')"
          :placeholder="$t('请输入审核备注')"
          :rules="[{ required: form.reviewStatus === '1013003' }]"
          maxlength="200"
          rows="3"
          autosize
          type="textarea"
        />
      </van-form>
    </div>
    <van-popup v-model="show" position="bottom" get-container="body">
      <van-datetime-picker
        v-if="show"
        title="选择年月日小时"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dateConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </van-dialog>
</template>

<style lang="less" scoped>
.defeat-from {
  padding: 20px;
}
</style>
