<!--
* @Author: tianzl
* @Date: 2023-12-05 13:46:28
* @LastEditors: tianzl
* @LastEditTime: 2023-12-05 13:46:28
* @Description: 线索详情
 -->
 <template>
  <div class="pageBox">
    <!-- <van-nav-bar
      v-if="!$isMobile && $route.query.contextEntry == 'single_chat_tools'"
      fixed
      placeholder
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      z-index="1000"
      @click-left="$router.back()"
    /> -->
    <div class="top card">
      <UserInfo :data="info" :isShowViMobile="false" :isDesensitization="true" :isShowCopyMobile="phoneCopyFlag" @edit="onEdit"/>
      <div class="info">      
        <!-- <div>{{ info.seriesName }} {{info.modelName}}</div>   -->
        <div><span class="label">用户来源：</span>{{getChannelName || '--'}}</div>
        <CardOptions :data="info"/>
        <!-- <div><span class="label">关注竞品：</span>{{ info.detail.rival || '--' }}</div> -->
        <!-- <div><span class="label">客户留资时间：</span>{{ info.createTime }}</div> -->
        <div class="tags">
          <Tag v-for="(item) in info.labels" :key="item" class="tag" plain size="medium">{{item}}</Tag>
        </div>
        <div><span class="label">跟进人：</span>{{info.followUserName || '--'}} | {{ info.hatchState === '1000012' && info.dealerName || '--' }}</div>
        <div v-if="info.relationVo && info.relationVo.id" style="display: flex;">
          <span class="label" @click="onQuestion">关联线索<van-icon name="question-o" /></span>
          <span>：</span>
          <span>{{ info.relationVo.userName }}</span>
          <span style="margin:0 0.5em;"> | </span>
          <span>{{ info.relationVo.userMobile ? info.relationVo.userMobile.replace(/^(1[3-9][0-9])\d{4}(\d{4}$)/, '$1***$2') : '' }}</span>
          <span style="margin-left: auto;color: #EED484;" @click="$router.push('/clue-incubation/details?id=' + info.relationVo.id)">查看详情</span>
        </div>
      </div>
      <div class="status">{{ info.hatchState | codeFormat }} </div>
    </div>
    <div class="card noPadding">
      <van-tabs v-model="type" @change="onTabChange">
        <van-tab
          v-for="item in tabs"
          :key="item.value"
          :title="item.name"
          :name="item.value"
        ></van-tab>
      </van-tabs>  
      <Trips v-if="type===0" ref="trips" :details="tripDetails" @change="onChangeTrip"/>
      <Tags v-if="type===1" :id="id" @updateClueInfo="handleRegetClueInfo"></Tags>

    </div>
    <div v-if="['1000014', '1000013'].includes(info.hatchState)" class="btns">
      <van-button round class="btn" type="primary" style="width: 100%;" @click="visible = true">{{$t(info.hatchState == 1000014 ? '战败审核' : '无效审核')}}</van-button>
    </div>
    <div v-else-if="$route.query.origin !== 'approveManage'" class="btns">
      <div v-if="showMore" style="margin-right: auto;flex-shrink: 0">
        <van-popover
          v-model="showPopover"
          theme="dark"
          trigger="click"
          placement="top-start"
          style="margin-right: 8px;"
        >
          <ul style="padding: 6px 0;">
            <li v-if="!info.isInTransfer && ['1000012'].includes(info.hatchState) && ['2000000', '2000005', '2000010'].includes(info.state)" style="padding: 6px 16px;" @click="onClickAssigned">
              改派门店
            </li>
            <li v-if="!info.userWeChat" style="padding: 6px 16px 6px 8px;">
              <ExternalUserIcon :id="info.id" :isShowImg="false" name="绑定微信客户" @selectUser="onReload"></ExternalUserIcon>
            </li>
            <li v-else-if="!['1000012'].includes(info.hatchState)" style="padding: 6px 16px;" @click="deleteUserWeChat">
              解除微信客户关联
            </li>
          </ul>
          <template #reference>
            <span class="more">{{$t('更多')}}</span>
          </template>
        </van-popover>
      </div>
      <van-button v-if="info.id && !info.userWeChat && !['1000012', '1000016', '1000017', '1000018'].includes(info.hatchState)" round class="btn" type="primary" @click="SMSAddwechat">
        <span>邀请加微</span>
        <span class="time">上次邀请：{{ getTimeTextYMD(info.inviteAddWechatTime) }}</span>
      </van-button>
      <van-button v-if="info.hatchUserId"  round class="btn" type="primary" style="margin-left: 8px;" @click="follow">
        <span>填写培育记录</span>
        <span v-if="['1000005', '1000010', '1000011'].includes(info.hatchState)" class="time">截止时间：{{ info.planTime | datetimeText }}</span>
      </van-button>
    </div>
    <ReviewFrom
      v-model="visible"
      :type="info.hatchState === '1000014' ? 1 : 2"
      :checkedItems="info.leadFail ? [info.leadFail.id] : []"
      @confirm="handlerConfirm"
    />
  </div>
</template>
<script>
import Tag from '@/components/card-view/tag.vue'
import UserInfo from '@/components/detail-view/user-info.vue'
import AddWx from '@/components/detail-view/add-wx.vue'
import Trips from '@/modules/clue/component/trips/index.vue'
import Tags from '@/modules/clue/component/tags/index.vue'
import CardOptions from '@/components/card-view/cardOptions.vue'
import clueServices from '@/services/clueServices'
import { getHatchRuleDetail } from '@/services/clueIncubationServices.js'
import { getTimeTextYMD } from '@/utils'
import ExternalUserIcon from '@/components/card-view/external-user-icon.vue'
import ReviewFrom from './reviewFrom.vue'
import loading from '@/utils/loading'

export default {
  components:{ UserInfo, Tag, AddWx, Trips, Tags, CardOptions, ReviewFrom, ExternalUserIcon },
  filters: {
    statusShow(data) {
      // 0：已取消、1：待确认、2：已确认、3：已完成
      return {
        0: '已取消',
        1: '待确认',
        2: '已确认',
        3: '已完成',
      }[data.appointmentTypeCode === '2001002' ? data.arrivalStatus : data.status]
    }
  },
  data() {
    return {
      visible: false,
      type: 1,
      tabs: [
        {
          name: this.$t('标签'),
          value: 1,
        },
        {
          name: this.$t('轨迹'),
          value: 0,
        },
      ],
      info:{
        detail:{},
        labels:[]
      },
      tripDetails:{},
      id: '', // 线索id
      phoneCopyFlag: true,
      showPopover: false
    }
  },
  computed: {
    showMore() {
      const { userWeChat, hatchState, isInTransfer, state, id } = this.info
      return (id && (!userWeChat || !['1000012'].includes(hatchState)) && !['single_chat_tools', 'group_chat_tools'].includes(this.$route.query.contextEntry)) || (!isInTransfer && ['1000012'].includes(hatchState) && ['2000000', '2000005', '2000010'].includes(state))
    },
    getChannelName() {
      const { onlineName, channelOne, channelTwo, channelThree, channelFour, channelFive } = this.info
      const arr = []
      onlineName && arr.push(onlineName)
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      channelFour && arr.push(channelFour)
      channelFive && arr.push(channelFive)
      return arr.join('/')
    },
    showIcon() {
      return !['1000013', '1000014', '1000016', '1000017'].includes(this.info.hatchState)
    },
    // isShowFollowBtn(){
    //   return ['1000005','1000010','1000011','1000012'].includes(this.info.hatchState)&&(this.getUserInfo.id===this.info.hatchUserId)
    // },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  watch: {
    $route(to, from) {
      const { id } = this.$route.query
      this.id = id
      this.getClueDetail()
      this.getTrips()
      // 对路由变化作出响应...
    }
  },
  mounted(){
    this.getTrips()
  },
  created() {
    const { id } = this.$route.query
    this.id = id
    this.getClueDetail()
  },
  methods:{
    onChangeTrip(code){
      this.getTrips(code)
    },
    onReload() {
      this.showPopover = false
      this.getClueDetail()
      this.getTrips()
    },
    async deleteUserWeChat() {
      await this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t(
          `确认解除线索与微信客户“${this.info.userWeChatName}”的绑定关系 ？`
        ),
        confirmButtonColor: '#B9921A',
      })
      await clueServices.unBindWechat({ id: this.id })
      this.onReload()
    },
    handlerConfirm() {
      this.$router.back()
    },
    onQuestion() {
      this.$dialog.alert({ message: '客户提供其他联系号码，中央客服战败原线索，使用其他联系号码创建新线索，并手动关联原线索', confirmButtonColor: '#EED484' })
    },
    onClickAssigned() {
      this.$router.push({
        path:'/clue-incubation/assigned',
        query: {
          dealerName: this.info.dealerName,
          id: this.info.id,
        }
      })
    },
    getTimeTextYMD,
    onTabChange() {
 
    },
    handleRegetClueInfo() {
      this.getClueDetail()
    },
    selectUser() {
      this.getClueDetail()
    },
    getClueDetail() {
      clueServices.getHatchClueDetail({ id: this.id }).then(res=>{
        this.info = res
      })
      getHatchRuleDetail({}).then(res=>{
        const { phoneCopyFlag= true } = res || {}
        this.phoneCopyFlag = phoneCopyFlag
      })
    },
    // 编辑
    onEdit(data){
      this.$router.push({
        path:'/clue-incubation/edit',
        query:{
          id: this.id,
          operate: data,
          contextEntry: this.$route.query.contextEntry
        }
      })
    },
    // 获取轨迹
    async getTrips(label){
      const { id } =this
      const params = { id }
      if (label) params.label = label
      loading.showLoading()
      const res = await clueServices.getTrips(params).finally(() => loading.hideLoading())
      this.tripDetails = res
    },
    // 填写培育记录 
    follow(){
      this.$router.push({
        path:'/clue-incubation/follow',
        query:{
          id:this.id
        }
      })
    },
    SMSAddwechat() {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: '确认发送短信，邀请客户添加企业微信好友 ？',
        confirmButtonColor: '#B9921A',
      }).then(async () => {
        await clueServices.followClue({ followResult: '1011042', id: this.id, followRemark: '短信邀请加微' })
        this.getClueDetail()
        this.$toast('发送短信成功')
      })
    }
  }
}
</script>

 <style lang="less" scoped>
 .card{
   background-color: #fff;
   border-radius: 8px;
   padding:12px;
   margin-bottom: 16px;
   &.noPadding{
     padding-top: 0;
   }
 }
 .label{
   color: #929798;
 }
 .pageBox{
    padding: 16px;
    padding-bottom: 96px;
    /deep/ .van-nav-bar--fixed {
      left: 50%;
      max-width: 500px;
      transform: translateX(-50%);
    }
   .top{
     font-size: 13px;
     position: relative;
     .status{
        position: absolute;
        top:12px;
        right:12px;
      }
     .info{
       border-top:1px solid #F3F3F4;
       margin-top:12px;
       padding-top:12px;
       position: relative;
       >div:not(.icons,.add,.tags){
         margin-bottom: 12px;
       }
       
       .tags{
         .tag{
           margin-right: 8px;
           margin-bottom: 8px;
         }
       }
       .add{
         border-top:1px solid #F3F3F4;
         padding-top:12px;
       }
       .icons{
        position: absolute;
        top: 30%;
        right: 12px;
        display:flex;
        flex-direction: column;
        .icon{
          margin-bottom: 16px;
        }
      }
     }
   }
   .appointment{
     .title{
       margin-bottom: 12px;
       border-bottom: 1px solid #F3F3F4;
       padding-bottom: 12px;
       .status{
         padding: 2px 5px;
         background: @yellow;
         color: #fff;
         font-size: 12px;
         border-radius: 6px;
         margin-left: 4px;
         &.active{
           background: #00C800;
         }
       }
     }
     .info{
       font-size: 13px;
       color:#0D171A;
       .label{
         margin-right: 12px;
       }
       >div{
         margin-bottom: 6mm;
       }
     }
   }
   .carNumber{
     background-color: #3476FE;
     color:#fff;
     border-radius: 2px;
     margin-left: 12px;
     padding:2px 4px;
   }
 }

 .btns{
    max-width: 500px;
    width: 100%;
    height: 96px;
    display: flex;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    margin-left: -16px;
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 16px;
    justify-content: flex-end;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: @black;
    box-shadow: 0px 2px 8px 2px #ddd;
    
    .btn{
      width: 190px;
      border-radius: 100px;
      cursor: pointer;
      .van-button__text{
        display: flex;
        flex-direction: column;
        .time{
          font-size: 12px;
          // color: #E4002C;
        }
      }
    }

  }
 </style>
 